import { h } from 'hyperapp';
import { location } from 'hyperapp-router';
import mdui from 'mdui';
import { fullPath } from '~/utils/path';
import { removeCookie } from '~/utils/cookie';
import './index.less';
import AvatarUpload from '~/components/avatar-upload/view.jsx';
let menu;

const Button = ({
  user
}) => h("div", {
  class: "user mdui-btn mdui-btn-icon mdui-btn-dense",
  title: `帐号：${user.username}\n(${user.email})`,
  oncreate: element => {
    menu = new mdui.Menu(element, '#appbar-avatar-popover', {
      covered: false
    });
  }
}, h("img", {
  src: user.avatar.middle,
  width: "32",
  height: "32"
}));

const PopoverBottomButton = ({
  onClick,
  text
}) => h("button", {
  onclick: onClick,
  class: "mdui-btn mdui-btn-dense mdui-btn-outlined mdui-color-theme mdui-ripple"
}, text);

const Popover = ({
  user
}) => h("div", {
  class: "popover mdui-menu",
  id: "appbar-avatar-popover"
}, h("div", {
  class: "info"
}, h("div", {
  class: "avatar-box"
}, h(AvatarUpload, {
  user: user
}), h("img", {
  src: user.avatar.large,
  width: "96",
  height: "96"
})), h("div", {
  class: "username"
}, user.username), h("div", {
  class: "email mdui-text-color-theme-secondary"
}, user.email), h("button", {
  class: "personal mdui-btn mdui-btn-dense mdui-btn-outlined mdui-color-theme mdui-ripple",
  onclick: () => {
    const url = fullPath(`/users/${user.user_id}`);
    location.actions.go(url);
    menu.close();
  }
}, "\u4E2A\u4EBA\u8D44\u6599")), h("div", {
  class: "bottom"
}, user.user_id === 1 ? h(PopoverBottomButton, {
  onClick: () => {
    window.location.href = fullPath('/admin');
    menu.close();
  },
  text: "\u7BA1\u7406\u540E\u53F0"
}) : null, h("div", {
  class: "mdui-toolbar-spacer"
}), h(PopoverBottomButton, {
  onClick: () => {
    removeCookie('token');
    window.localStorage.removeItem('token');
    window.location.reload();
  },
  text: "\u9000\u51FA"
})));

export default (({
  user
}) => h("div", {
  class: "avatar"
}, h(Button, {
  user: user
}), h(Popover, {
  user: user
})));