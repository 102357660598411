import { h } from 'hyperapp';
import cc from 'classcat';
export default (({
  value,
  message,
  image,
  hide,
  onInput,
  onRefresh
}) => h("div", {
  class: cc(['mdui-textfield', 'mdui-textfield-floating-label', 'mdui-textfield-has-bottom', 'captcha-field', {
    'mdui-textfield-invalid': message,
    'mdui-textfield-not-empty': value,
    'mdui-hidden': hide
  }])
}, h("label", {
  class: "mdui-textfield-label"
}, "\u56FE\u5F62\u9A8C\u8BC1\u7801"), h("input", {
  oninput: onInput,
  value: value,
  class: "mdui-textfield-input",
  name: "captcha_code",
  type: "text",
  autocomplete: "off",
  required: !hide
}), h("div", {
  class: "mdui-textfield-error"
}, message || '请输入图形验证码'), h("img", {
  class: "captcha-image",
  src: image,
  title: "\u70B9\u51FB\u5237\u65B0\u9A8C\u8BC1\u7801",
  onclick: onRefresh
})));