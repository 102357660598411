import { h } from 'hyperapp';
import Submit from '~/common/account/components/submit.jsx';

const Button = () => h("button", {
  type: "button",
  class: "mdui-btn mdui-ripple more-option",
  "mdui-menu": "{target: '#mc-password-reset-menu', position: 'top', covered: true}"
}, "\u66F4\u591A\u9009\u9879", h("i", {
  className: "mdui-icon material-icons mdui-text-color-theme-icon"
}, "arrow_drop_down"));

const Menu = ({
  actions
}) => h("ul", {
  class: "mdui-menu",
  id: "mc-password-reset-menu"
}, h("li", {
  class: "mdui-menu-item"
}, h("a", {
  onclick: actions.toLogin,
  class: "mdui-ripple"
}, "\u767B\u5F55\u8D26\u53F7")), h("li", {
  class: "mdui-menu-item"
}, h("a", {
  onclick: actions.toRegister,
  class: "mdui-ripple"
}, "\u521B\u5EFA\u65B0\u8D26\u53F7")));

export default (({
  state,
  actions
}) => h("div", {
  class: "actions"
}, h(Button, null), h(Menu, {
  actions: actions
}), h(Submit, {
  disabled: state.verifying,
  text: state.verifying ? '正在验证…' : '下一步'
})));