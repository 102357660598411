import { h } from 'hyperapp';
import { Link } from 'hyperapp-router';
import { fullPath } from '~/utils/path';
import './index.less';
export default (({
  count
}) => h(Link, {
  to: fullPath('/notifications'),
  class: "notification mdui-btn mdui-btn-icon",
  title: `${count ? `${count} 条` : '没有'}未读通知`
}, count ? h("span", {
  class: "count"
}) : null, h("i", {
  class: "mdui-icon material-icons mdui-text-color-theme-icon"
}, "notifications")));