import { h } from 'hyperapp';
import './index.less';
import Close from '~/common/account/components/close.jsx';
import Title from '~/common/account/components/title.jsx';
import FieldName from '~/common/account/components/field-name.jsx';
import FieldPassword from '~/common/account/components/field-password.jsx';
import FieldCaptcha from '~/common/account/components/field-captcha.jsx';
import Actions from './components/actions.jsx';
export default (({
  state,
  actions
}) => h("div", {
  oncreate: element => actions.onCreate({
    element
  }),
  key: "login",
  class: "mc-account mc-login mdui-dialog"
}, h(Close, {
  onClick: actions.close,
  icon: "close"
}), h(Title, {
  text: "\u767B\u5F55"
}), h("form", {
  onsubmit: actions.login
}, h(FieldName, {
  value: state.name,
  message: state.name_msg,
  label: "\u7528\u6237\u540D\u6216\u90AE\u7BB1",
  name: "name",
  error: state.name_msg || '账号不能为空',
  onInput: actions.onInput
}), h(FieldPassword, {
  value: state.password,
  message: state.password_msg,
  label: "\u5BC6\u7801",
  name: "password",
  onInput: actions.onInput
}), h(FieldCaptcha, {
  value: state.captcha_code,
  message: state.captcha_code_msg,
  image: state.captcha_image,
  hide: !state.captcha_token,
  onInput: actions.onInput,
  onRefresh: actions.refreshCaptcha
}), h(Actions, {
  state: state,
  actions: actions
}))));