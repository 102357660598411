import { h } from 'hyperapp';
import { Link } from 'hyperapp-router';
import { fullPath } from '~/utils/path';
import './index.less';
export default (({
  title,
  url
}) => h(Link, {
  class: "header mdui-ripple",
  to: fullPath(url)
}, h("div", {
  class: "mdui-text-color-theme-secondary"
}, title), h("i", {
  class: "mdui-icon material-icons mdui-text-color-theme"
}, "arrow_forward")));