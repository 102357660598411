import { h } from 'hyperapp';
import cc from 'classcat';
import './index.less';
export default (({
  show
}) => h("div", {
  class: cc(['mc-loaded', {
    'mdui-hidden': !show
  }]),
  key: "mc-loaded"
}, "\u5DF2\u52A0\u8F7D\u5B8C\u6240\u6709\u6570\u636E"));