import { h } from 'hyperapp';
import './index.less';
export default (({
  topic,
  onRemove
}) => h("div", {
  class: "mdui-chip",
  key: topic.topic_id
}, h("img", {
  class: "mdui-chip-icon",
  src: topic.cover.small
}), h("span", {
  class: "mdui-chip-title"
}, topic.name), h("span", {
  class: "mdui-chip-delete",
  onclick: onRemove
}, h("i", {
  class: "mdui-icon material-icons"
}, "cancel"))));