import { h } from 'hyperapp';
import { Link } from 'hyperapp-router';
import { fullPath } from '~/utils/path';
import './index.less';
import Follow from '~/components/follow/view.jsx';
import OptionsButton from '~/components/options-button/view.jsx';
export default (({
  user,
  actions
}) => h("div", {
  class: "item-inner",
  key: user.user_id
}, h("div", {
  class: "item mdui-card"
}, h(Link, {
  to: fullPath(`/users/${user.user_id}`),
  class: "mdui-ripple info",
  onclick: () => actions.afterItemClick(user)
}, h("div", {
  class: "avatar",
  style: {
    backgroundImage: `url("${user.avatar.large}")`
  }
}), h("div", {
  class: "username mdui-text-color-theme-text"
}, user.username), h("div", {
  class: "headline mdui-text-color-theme-secondary"
}, user.headline)), h("div", {
  class: "actions"
}, !user.relationships.is_me ? h(Follow, {
  item: user,
  type: "users",
  id: user.user_id,
  actions: actions
}) : null, h("div", {
  class: "flex-grow"
}), h(OptionsButton, {
  type: "user",
  item: user
})))));