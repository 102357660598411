import { h } from 'hyperapp';
import cc from 'classcat';
import $ from 'mdui.jq';
import { plainText } from '~/utils/html';
import './index.less';
import UserLine from '~/components/user-line/view.jsx';
import Vote from '~/components/vote/view.jsx';
import OptionsButton from '~/components/options-button/view.jsx';
import IconButton from '~/components/icon-button/view.jsx';
import Loading from '~/components/loading/view.jsx';
import NewComment from '../new-comment/view.jsx';
import Reply from '../reply/view.jsx';
export default (({
  comment,
  commentIndex,
  actions
}) => {
  const isRepliesLoaded = !comment.replies_loading && comment.replies_pagination && comment.replies_pagination.page === comment.replies_pagination.pages;
  const isRepliesLoading = comment.replies_loading;
  return h("div", {
    class: "item",
    key: comment.comment_id,
    oncreate: element => $(element).mutation()
  }, h(UserLine, {
    actions: actions,
    user: comment.relationships.user,
    time: comment.create_time,
    dataName: "comments_data",
    primaryKey: "comment_id",
    primaryValue: comment.comment_id
  }), h("div", {
    class: "content mdui-typo",
    oncreate: plainText(comment.content),
    onupdate: plainText(comment.content)
  }), h("div", {
    class: "actions"
  }, h(Vote, {
    actions: actions,
    item: comment,
    type: "comments"
  }), h(IconButton, {
    icon: "reply",
    tooltip: "\u53D1\u8868\u56DE\u590D",
    onClick: () => {
      actions.toggleState({
        comment_id: comment.comment_id,
        fieldName: 'show_new_reply'
      });
    }
  }), h("div", {
    class: "flex-grow"
  }), h(OptionsButton, {
    type: "comment",
    item: comment
  })), comment.show_new_reply ? h(NewComment, {
    placeholder: "\u6DFB\u52A0\u56DE\u590D...",
    submitting: comment.reply_submitting,
    onCreate: element => {
      setTimeout(() => $(element).find('textarea')[0].focus());
    },
    onSubmit: ({
      target
    }) => {
      actions.submitReply({
        target,
        comment
      });
    }
  }) : null, comment.reply_count ? h("div", {
    class: cc(['reply_count', {
      'show-replies': comment.show_replies
    }]),
    onclick: () => {
      actions.toggleState({
        comment_id: comment.comment_id,
        fieldName: 'show_replies'
      });
    }
  }, h("i", {
    class: "mdui-icon material-icons"
  }, "arrow_drop_down"), h("span", null, comment.show_replies ? '隐藏' : '查看', " ", comment.reply_count, ' ', "\u6761\u56DE\u590D")) : null, comment.show_replies ? h("div", {
    class: "replies"
  }, comment.replies_data.map(reply => h(Reply, {
    comment: reply,
    commentIndex: commentIndex,
    actions: actions
  })), h(Loading, {
    show: comment.replies_loading
  }), h("div", {
    class: cc(['reply_more', {
      'mdui-hidden': isRepliesLoaded || isRepliesLoading
    }]),
    onclick: () => {
      actions.loadReplies({
        comment
      });
    }
  }, h("i", {
    class: "mdui-icon material-icons"
  }, "subdirectory_arrow_right"), h("span", null, "\u663E\u793A\u66F4\u591A\u56DE\u590D"))) : null);
});