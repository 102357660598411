export default {
  /**
   * 举报类型：question, answer, article, comment, user
   */
  type: '',

  /**
   * 举报目标信息
   */
  item: null,

  /**
   * 举报原因
   */
  reason: ''
};