import { h } from 'hyperapp';
import './index.less';
import Loading from '~/components/loading/view.jsx';
import Loaded from '~/components/loaded/view.jsx';
import Empty from '~/components/empty/view.jsx';
import Item from './components/item/view.jsx';
export default ((state, actions) => ({
  match
}) => h("div", {
  oncreate: actions.onCreate,
  ondestroy: actions.onDestroy,
  key: match.url,
  id: "page-users",
  class: "mdui-container"
}, state.tabs.map(tabName => {
  const isEmpty = !state[`${tabName}_loading`] && !state[`${tabName}_data`].length && state[`${tabName}_pagination`];
  const isLoaded = !state[`${tabName}_loading`] && state[`${tabName}_pagination`] && state[`${tabName}_pagination`].page === state[`${tabName}_pagination`].pages;
  const isLoading = state[`${tabName}_loading`];
  return () => h("div", {
    id: tabName
  }, !isLoading && state[`${tabName}_data`].length ? h("div", {
    class: "subheading mdui-text-color-theme-secondary"
  }, tabName === 'followees' ? ["\u5DF2\u5173\u6CE8 ", state[`${tabName}_pagination`].total, " \u4EBA"] : null, tabName === 'followers' ? ["\u5173\u6CE8\u8005 ", state[`${tabName}_pagination`].total, " \u4EBA"] : null, tabName === 'recommended' ? "\u4EBA\u5458\u63A8\u8350" : null) : null, h("div", {
    class: "items-wrapper"
  }, state[`${tabName}_data`].map(user => h(Item, {
    user: user,
    actions: actions
  }))), tabName === 'followees' ? h(Empty, {
    show: isEmpty,
    title: "\u5C1A\u672A\u5173\u6CE8\u4EFB\u4F55\u7528\u6237",
    description: "\u5173\u6CE8\u7528\u6237\u540E\uFF0C\u76F8\u5E94\u7528\u6237\u5C31\u4F1A\u663E\u793A\u5728\u6B64\u5904\u3002",
    action: () => {
      actions.toRecommended();
    },
    action_text: "\u67E5\u770B\u63A8\u8350\u7528\u6237"
  }) : null, tabName === 'followers' ? h(Empty, {
    show: isEmpty,
    title: "\u4F60\u8FD8\u6CA1\u6709\u5173\u6CE8\u8005",
    description: "\u7528\u6237\u5173\u6CE8\u4E86\u4F60\u540E\uFF0C\u76F8\u5E94\u7528\u6237\u5C31\u4F1A\u663E\u793A\u5728\u6B64\u5904\u3002"
  }) : null, tabName === 'recommended' ? h(Empty, {
    show: isEmpty,
    title: "\u5DF2\u5173\u6CE8\u6240\u6709\u7528\u6237",
    description: "\u4F60\u672A\u5173\u6CE8\u7684\u7528\u6237\u4F1A\u663E\u793A\u518D\u6B64\u5904\u3002"
  }) : null, h(Loading, {
    show: isLoading
  }), h(Loaded, {
    show: isLoaded
  }));
})));