import { h } from 'hyperapp';
import cc from 'classcat';
export default (({
  onClick,
  icon
}) => h("button", {
  onclick: onClick,
  class: cc(['mdui-btn', 'mdui-btn-icon', icon])
}, h("i", {
  class: "mdui-icon material-icons"
}, icon)));