import { h } from 'hyperapp';
import { Link } from 'hyperapp-router';
import { fullPath } from '~/utils/path';
import './index.less';
import { plainText, richText, summaryText } from '~/utils/html';
import Loading from '~/components/loading/view.jsx';
export default (({
  notification,
  actions
}) => {
  // eslint-disable-next-line no-empty-pattern
  const Title = ({}, children) => h("div", {
    class: "title mdui-text-color-theme-text"
  }, children);

  const Content = ({
    content
  }) => {
    if (!notification.is_show_detail) {
      return h("div", {
        class: "content",
        key: "summary",
        oncreate: summaryText(content, 46),
        onupdate: summaryText(content, 46)
      });
    }

    if (notification.is_loaded_detail) {
      const contentFunc = ['comment_deleted', 'question_commented', 'article_commented', 'answer_commented', 'comment_replies'].indexOf(notification.type) > -1 ? plainText(notification.content_detail) : richText(notification.content_detail);
      return h("div", {
        class: "content mdui-typo",
        key: "richtext",
        oncreate: contentFunc,
        onupdate: contentFunc
      });
    }

    return h("div", {
      class: "content",
      key: "loading"
    }, h(Loading, {
      show: true
    }));
  };

  const More = () => h("div", {
    class: "mdui-btn mdui-btn-icon more",
    title: notification.is_show_detail ? '隐藏详细信息' : '显示详细信息',
    onclick: () => actions.toggleDetail(notification)
  }, h("i", {
    class: "mdui-icon material-icons"
  }, notification.is_show_detail ? 'arrow_drop_up' : 'arrow_drop_down'));

  const Delete = () => h("div", {
    class: "mdui-btn mdui-btn-icon delete",
    title: "\u5220\u9664\u8BE5\u901A\u77E5",
    onclick: () => actions.deleteOne(notification)
  }, h("i", {
    class: "mdui-icon material-icons"
  }, "close")); // eslint-disable-next-line no-empty-pattern


  const Item = ({}, children) => h("div", {
    class: "item"
  }, h(Delete, null), children, h(More, null)); // eslint-disable-next-line no-empty-pattern


  const ReplyWrapper = ({}, children) => h(Item, null, children, h(Content, {
    content: notification.relationships.reply.content_summary
  })); // eslint-disable-next-line no-empty-pattern


  const CommentDeletedWrapper = ({}, children) => h(Item, null, children, h(Content, {
    content: notification.content_deleted.content
  }));

  const UserLink = () => h(Link, {
    to: fullPath(`/users/${notification.sender_id}`),
    class: "user"
  }, notification.relationships.sender.username);

  const QuestionLink = () => h(Link, {
    to: fullPath(`/questions/${notification.question_id}`),
    class: "question"
  }, notification.relationships.question ? notification.relationships.question.title : notification.content_deleted.title);

  const ArticleLink = () => h(Link, {
    to: fullPath(`/articles/${notification.article_id}`),
    class: "article"
  }, notification.relationships.article ? notification.relationships.article.title : notification.content_deleted.title);

  switch (notification.type) {
    case 'question_answered':
      return h(Item, null, h(Title, null, h(UserLink, null), " \u56DE\u7B54\u4E86\u4F60\u7684\u63D0\u95EE ", h(QuestionLink, null)), h(Content, {
        content: notification.relationships.answer.content_summary
      }));

    case 'question_commented':
      return h(Item, null, h(Title, null, h(UserLink, null), " \u8BC4\u8BBA\u4E86\u4F60\u7684\u63D0\u95EE ", h(QuestionLink, null)), h(Content, {
        content: notification.relationships.comment.content_summary
      }));

    case 'question_deleted':
      return h(Item, null, h(Title, null, "\u4F60\u7684\u63D0\u95EE", ' ', h("span", {
        class: "question deleted"
      }, notification.content_deleted.title), ' ', "\u5DF2\u88AB\u5220\u9664"), h(Content, {
        content: notification.content_deleted.content_rendered
      }));

    case 'article_commented':
      return h(Item, null, h(Title, null, h(UserLink, null), " \u8BC4\u8BBA\u4E86\u4F60\u7684\u6587\u7AE0 ", h(ArticleLink, null)), h(Content, {
        content: notification.relationships.comment.content_summary
      }));

    case 'article_deleted':
      return h(Item, null, h(Title, null, "\u4F60\u7684\u6587\u7AE0", ' ', h("span", {
        class: "article deleted"
      }, notification.content_deleted.title), ' ', "\u5DF2\u88AB\u5220\u9664"), h(Content, {
        content: notification.content_deleted.content_rendered
      }));

    case 'answer_commented':
      return h(Item, null, h(Title, null, h(UserLink, null), " \u8BC4\u8BBA\u4E86\u4F60\u5728\u63D0\u95EE ", h(QuestionLink, null), " \u4E2D\u7684\u56DE\u7B54"), h(Content, {
        content: notification.relationships.comment.content_summary
      }));

    case 'answer_deleted':
      return h(Item, null, h(Title, null, "\u4F60\u5728\u63D0\u95EE ", h(QuestionLink, null), " \u4E2D\u7684\u56DE\u7B54\u5DF2\u88AB\u5220\u9664"), h(Content, {
        content: notification.content_deleted.content_rendered
      }));

    case 'comment_replied':
      if (notification.answer_id && notification.question_id) {
        return h(ReplyWrapper, null, h(Title, null, h(UserLink, null), " \u56DE\u590D\u4E86\u4F60\u5728\u63D0\u95EE ", h(QuestionLink, null), " \u7684\u56DE\u7B54\u4E2D\u7684\u8BC4\u8BBA"));
      }

      if (notification.question_id) {
        return h(ReplyWrapper, null, h(Title, null, h(UserLink, null), " \u56DE\u590D\u4E86\u4F60\u5728\u63D0\u95EE ", h(QuestionLink, null), " \u4E2D\u7684\u8BC4\u8BBA"));
      }

      if (notification.article_id) {
        return h(ReplyWrapper, null, h(Title, null, h(UserLink, null), " \u56DE\u590D\u4E86\u4F60\u5728\u6587\u7AE0 ", h(ArticleLink, null), " \u4E2D\u7684\u8BC4\u8BBA"));
      }

      return null;

    case 'comment_deleted':
      if (notification.answer_id && notification.question_id) {
        return h(CommentDeletedWrapper, null, h(Title, null, "\u4F60\u5728\u63D0\u95EE ", h(QuestionLink, null), " \u7684\u56DE\u7B54\u4E2D\u7684\u8BC4\u8BBA\u5DF2\u88AB\u5220\u9664"));
      }

      if (notification.question_id) {
        return h(CommentDeletedWrapper, null, h(Title, null, "\u4F60\u5728\u63D0\u95EE ", h(QuestionLink, null), " \u4E2D\u7684\u8BC4\u8BBA\u5DF2\u88AB\u5220\u9664"));
      }

      if (notification.article_id) {
        return h(CommentDeletedWrapper, null, h(Title, null, "\u4F60\u5728\u6587\u7AE0 ", h(ArticleLink, null), " \u4E2D\u7684\u8BC4\u8BBA\u5DF2\u88AB\u5220\u9664"));
      }

      return null;

    default:
      return null;
  }
});