export default {
  name: '',
  name_msg: '',
  password: '',
  password_msg: '',
  captcha_code: '',
  captcha_code_msg: '',
  captcha_token: '',
  captcha_image: '',
  submitting: false
};