import { h } from 'hyperapp';
import { location } from 'hyperapp-router';
import { fullPath } from '~/utils/path';
import './index.less';

const back = path => {
  if (window.history.state) {
    window.history.back();
  } else {
    location.actions.go(fullPath(path));
  }
};

export default (({
  path
}) => h("div", {
  class: "mc-nav"
}, h("button", {
  class: "back mdui-btn mdui-color-theme mdui-ripple",
  onclick: () => back(path)
}, h("i", {
  class: "mdui-icon mdui-icon-left material-icons"
}, "arrow_back"), "\u8FD4\u56DE")));