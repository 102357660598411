import { h } from 'hyperapp';
import './index.less';
import IconButton from '~/components/icon-button/view.jsx';
export default (({
  item,
  onClick
}) => h(IconButton, {
  cls: "comment",
  icon: "comment",
  tooltip: "\u67E5\u770B\u8BC4\u8BBA",
  badge: item.comment_count,
  onClick: onClick
}));