import { h } from 'hyperapp';
import cc from 'classcat';
export default (({
  value,
  message,
  label,
  name,
  error,
  onInput
}) => h("div", {
  class: cc(['mdui-textfield', 'mdui-textfield-floating-label', 'mdui-textfield-has-bottom', {
    'mdui-textfield-invalid': message,
    'mdui-textfield-not-empty': value
  }])
}, h("label", {
  class: "mdui-textfield-label"
}, label), h("input", {
  oninput: onInput,
  value: value,
  class: "mdui-textfield-input",
  name: name,
  type: "text",
  required: "required"
}), h("div", {
  class: "mdui-textfield-error"
}, error)));