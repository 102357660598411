import { h } from 'hyperapp';
import cc from 'classcat';
export default (({
  value,
  message,
  onInput
}) => h("div", {
  class: cc(['mdui-textfield', 'mdui-textfield-floating-label', 'mdui-textfield-has-bottom', {
    'mdui-textfield-invalid': message,
    'mdui-textfield-not-empty': value
  }])
}, h("label", {
  class: "mdui-textfield-label"
}, "\u90AE\u7BB1"), h("input", {
  oninput: onInput,
  value: value,
  class: "mdui-textfield-input",
  name: "email",
  type: "email",
  required: "required"
}), h("div", {
  class: "mdui-textfield-error"
}, message || '邮箱格式错误')));