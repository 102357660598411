import { h } from 'hyperapp';
import $ from 'mdui.jq';
import cc from 'classcat';
import './index.less';
export default (({
  show
}) => h("div", {
  key: "mc-loading",
  class: cc(['mc-loading', {
    'mdui-hidden': !show
  }])
}, h("div", {
  class: "mdui-spinner",
  oncreate: element => $(element).mutation()
})));