import { h } from 'hyperapp';
import './index.less';
import Loading from '~/components/loading/view.jsx';
import Loaded from '~/components/loaded/view.jsx';
import Empty from '~/components/empty/view.jsx';
import Item from '~/components/topic-item/view.jsx';
export default ((state, actions) => ({
  match
}) => h("div", {
  oncreate: actions.onCreate,
  ondestroy: actions.onDestroy,
  key: match.url,
  id: "page-topics",
  class: "mdui-container"
}, state.tabs.map(tabName => {
  const isEmpty = !state[`${tabName}_loading`] && !state[`${tabName}_data`].length && state[`${tabName}_pagination`];
  const isLoaded = !state[`${tabName}_loading`] && state[`${tabName}_pagination`] && state[`${tabName}_pagination`].page === state[`${tabName}_pagination`].pages;
  const isLoading = state[`${tabName}_loading`];
  return () => h("div", {
    id: tabName
  }, !isLoading && state.following_data.length ? h("div", {
    class: "subheading mdui-text-color-theme-secondary"
  }, tabName === 'following' ? ["\u5DF2\u5173\u6CE8 ", state[`${tabName}_pagination`].total, " \u4E2A\u8BDD\u9898"] : null, tabName === 'recommended' ? "\u8BDD\u9898\u63A8\u8350" : null) : null, h("div", {
    class: "items-wrapper"
  }, state[`${tabName}_data`].map(topic => h(Item, {
    topic: topic,
    actions: actions
  }))), tabName === 'following' ? h(Empty, {
    show: isEmpty,
    title: "\u5C1A\u672A\u5173\u6CE8\u4EFB\u4F55\u8BDD\u9898",
    description: "\u5173\u6CE8\u8BDD\u9898\u540E\uFF0C\u76F8\u5E94\u8BDD\u9898\u5C31\u4F1A\u663E\u793A\u5728\u6B64\u5904\u3002",
    action: () => {
      actions.toRecommended();
    },
    action_text: "\u67E5\u770B\u7CBE\u9009\u8BDD\u9898"
  }) : null, tabName === 'recommended' ? h(Empty, {
    show: isEmpty,
    title: "\u7BA1\u7406\u5458\u672A\u53D1\u5E03\u4EFB\u4F55\u8BDD\u9898",
    description: "\u5F85\u7BA1\u7406\u5458\u53D1\u5E03\u8BDD\u9898\u540E\uFF0C\u4F1A\u663E\u793A\u5728\u6B64\u5904"
  }) : null, h(Loading, {
    show: isLoading
  }), h(Loaded, {
    show: isLoaded
  }));
})));