import { h } from 'hyperapp';
import $ from 'mdui.jq';
import mdui from 'mdui';
import { Link } from 'hyperapp-router';
import { fullPath, isPathArticle, isPathArticles, isPathInbox, isPathIndex, isPathNotifications, isPathQuestion, isPathQuestions, isPathTopic, isPathTopics, isPathUser, isPathUsers } from '~/utils/path';
import { emit } from '~/utils/pubsub';
import './index.less';
import Avatar from './components/avatar/view.jsx';
import Notification from './components/notification/view.jsx';
import SearchBar from './components/search-bar/view.jsx';
import SearchIcon from './components/search-icon/view.jsx';

const Title = ({
  interviewee
}) => h("div", {
  class: "title"
}, isPathIndex() ? "\u9996\u9875" : isPathQuestions() || isPathQuestion() ? "\u95EE\u7B54" : isPathArticles() || isPathArticle() ? "\u6587\u7AE0" : isPathTopics() || isPathTopic() ? "\u8BDD\u9898" : isPathUsers() ? "\u4EBA\u8109" : isPathUser() && interviewee ? interviewee.username : isPathInbox() ? "\u79C1\u4FE1" : isPathNotifications() ? "\u901A\u77E5" : null);

const LoginBtn = () => h("div", {
  class: "login mdui-btn mdui-btn-dense",
  onclick: () => emit('login_open')
}, "\u767B\u5F55");

const RegisterBtn = () => h("div", {
  class: "register mdui-btn mdui-btn-dense",
  onclick: () => emit('register_open')
}, "\u6CE8\u518C");

export default (({
  user,
  interviewee,
  notifications
}) => h("div", {
  class: "toolbar mdui-toolbar"
}, h("button", {
  class: "drawer mdui-btn mdui-btn-icon mdui-ripple",
  oncreate: element => {
    const $drawer = $('.mc-drawer');
    const drawer = new mdui.Drawer($drawer, {
      swipe: true
    });
    $drawer.data('drawerInstance', drawer);
    $(element).on('click', () => drawer.toggle());
  }
}, h("i", {
  class: "mdui-icon material-icons"
}, "menu")), h(Link, {
  class: "headline",
  to: fullPath('/')
}, window.G_OPTIONS.site_name), h(Title, {
  interviewee: interviewee
}), h(SearchBar, null), h("div", {
  class: "mdui-toolbar-spacer"
}), h(SearchIcon, null), user ? [h(Notification, {
  count: notifications.count,
  key: "0"
}), h(Avatar, {
  user: user,
  key: "1"
})] : null, !user ? [h(LoginBtn, {
  key: "0"
}), h(RegisterBtn, {
  key: "1"
})] : null));